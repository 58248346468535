import { ScoreType } from './ScoreEntry'

type ScoreTypeDetails = {
  title: string
  description: string
}

export const getScoreTypeDetails = (scoreType: ScoreType): ScoreTypeDetails => {
  switch (scoreType) {
    case ScoreType.AMRAP:
      return {
        title: 'AMRAP',
        description: 'Entries are ranked by how many rounds and reps are complete in a set time.',
      }
    case ScoreType.REPS:
      return {
        title: 'Reps',
        description: 'Entries are ranked by how many reps are complete in a set time.',
      }
    case ScoreType.TIME:
      return {
        title: 'Quickest Time',
        description: 'Entries are ranked by the shortest time first.',
      }
    case ScoreType.ENDURANCE:
      return {
        title: 'Endurance Time',
        description: 'Entries are ranked by the longest time first.',
      }
    case ScoreType.WEIGHT:
      return {
        title: 'Weight',
        description: 'Entries are ranked by the highest successful weight first.',
      }
    case ScoreType.NUMBER:
      return {
        title: 'Number score', // TODO(sean): Add here support for number types big label, will take some thought
        description:
          "Challenge score is a number that doesn't fit neatly into any other type, ranked by highest number first.",
      }
    // Default needed because we can't be SURE will be valid ScoreType from backend
    default:
      return {
        title: 'Unknown',
        description: 'Unknown',
      }
  }
}

export const scoreTypeDetailsMap: Map<ScoreType, ScoreTypeDetails> = new Map([
  [ScoreType.AMRAP, getScoreTypeDetails(ScoreType.AMRAP)],
  [ScoreType.REPS, getScoreTypeDetails(ScoreType.REPS)],
  [ScoreType.TIME, getScoreTypeDetails(ScoreType.TIME)],
  [ScoreType.ENDURANCE, getScoreTypeDetails(ScoreType.ENDURANCE)],
  [ScoreType.WEIGHT, getScoreTypeDetails(ScoreType.WEIGHT)],
  [ScoreType.NUMBER, getScoreTypeDetails(ScoreType.NUMBER)],
])
