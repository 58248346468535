import { css, cx } from '@emotion/css'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'
import { FC, Fragment, useState } from 'react'
import tw from 'twin.macro'

import { ScoreType } from '@/domain/challenge/ScoreEntry'

import {
  getScoreTypeDetails,
  scoreTypeDetailsMap,
} from '../../../domain/challenge/ScoreTypeDetails'

export type ChallengeTypeSelectProps = {
  value?: ScoreType
  onChange: (type: ScoreType) => void
}

export const ChallengeTypeSelect: FC<ChallengeTypeSelectProps> = ({ value, onChange }) => {
  const [selectedValue, setSelectedValue] = useState(value || ScoreType.AMRAP)
  const selectedDetails = getScoreTypeDetails(selectedValue)

  const selectValue = (type: ScoreType) => {
    setSelectedValue(type)
    onChange(type)
  }

  return (
    <Listbox value={selectedValue} onChange={selectValue}>
      {({ open }) => (
        <div>
          <Listbox.Label tw="sr-only">Change challenge type</Listbox.Label>
          <div tw="relative">
            <div tw="inline-flex shadow-sm rounded-md w-full divide-x divide-indigo-600">
              <div tw="relative z-0 inline-flex flex-1 shadow-sm rounded-md divide-x divide-gray-600">
                <div tw="relative inline-flex items-center flex-1 bg-gray-500 py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white">
                  <CheckIcon tw="h-5 w-5" aria-hidden="true" />
                  <span tw="ml-2.5 text-lg font-medium">{selectedDetails.title}</span>
                </div>
                <Listbox.Button tw="relative inline-flex items-center bg-gray-500 p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-gray-600 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-500">
                  <span tw="sr-only">Change challenge type</span>
                  <ChevronDownIcon tw="h-5 w-5 text-white" aria-hidden="true" />
                </Listbox.Button>
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              {...{
                leave: cx(css(tw`transition ease-in duration-100`)),
                leaveFrom: cx(css(tw`opacity-100`)),
                leaveTo: cx(css(tw`opacity-0`)),
              }}
            >
              <Listbox.Options tw="origin-top-right absolute z-10 right-0 top[-10rem] mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
                {Array.from(scoreTypeDetailsMap.entries()).map(([value, details]) => (
                  <Listbox.Option key={details.title} as={Fragment} value={value}>
                    {({ selected, active }) => (
                      <div
                        css={active ? tw`text-white bg-gray-500` : tw`text-gray-900`}
                        tw="cursor-default select-none relative p-4 text-sm"
                      >
                        <div tw="flex flex-col">
                          <div tw="flex justify-between">
                            <span css={selected ? tw`font-semibold` : tw`font-normal`}>
                              {details.title}
                            </span>
                            {selected ? (
                              <span css={active ? tw`text-white` : tw`text-gray-500`}>
                                <CheckIcon tw="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </div>
                          <span tw="mt-2" css={active ? tw`text-gray-200` : tw`text-gray-500`}>
                            {details.description}
                          </span>
                        </div>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  )
}
